import { render, staticRenderFns } from "./newAdd.vue?vue&type=template&id=709b2d90"
import script from "./newAdd.vue?vue&type=script&lang=js"
export * from "./newAdd.vue?vue&type=script&lang=js"
import style0 from "./newAdd.vue?vue&type=style&index=0&id=709b2d90&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports